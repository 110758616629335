<script setup lang="ts">
const emits = defineEmits(['on-confirm', 'on-cancel']);
const props = defineProps({
  textPopover: String,
  iconOnly: Boolean,
});
</script>

<template>
  <div>
    <el-popconfirm
      confirm-button-text="Oui"
      cancel-button-text="Non"
      icon-color="#626AEF"
      :title="props.textPopover"
      :width="350"
      @confirm="$emit('on-confirm')"
      @cancel="$emit('on-cancel')"
    >
      <template #reference>
        <BaseButton
          type="warning"
          icon="material-symbols:delete"
          :text="!props.iconOnly ? 'Supprimer' : null"
        ></BaseButton>
      </template>
    </el-popconfirm>
  </div>
</template>

<style scoped></style>
